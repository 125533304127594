//  Variables

$header-height-sm: 64px;
$header-height: 74px;
$header-height-lg: 84px;
$header-height-xl: 94px;

$header-link-color-light: rgba($white, 0.8);
$header-link-hover-color-light: $white;
$header-link-hover-bg-light: rgba($white, 0.15);

$header-link-color-dark: rgba($black, 0.8);
$header-link-hover-color-dark: $black;
$header-link-hover-bg-dark: rgba($black, 0.07);
