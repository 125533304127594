// Core

@import "core";

// Fonts

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";

// Custom Css
@import "custom";

html {
  scroll-behavior: smooth;
}
